import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Introduction from "../screens/introduction";
import SignUp from "../screens/signup";
import RedirectScreen from "../screens/redirect";
import SendCode from "../screens/sendCode";
import VerifyCode from "../screens/verifyCode";

const Navigation: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/signup">
          <Route path="intro" element={<Introduction />} />
          <Route path="create-account" element={<SignUp />} />
          <Route path="send-code" element={<SendCode />} />
          <Route path="verify-code" element={<VerifyCode />} />
          <Route path="complete" element={<RedirectScreen />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default Navigation;
