import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 10px;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 15px;
    padding-bottom: 100px;
  }
`;

export const Subtitle = styled.label`
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  max-width: 420px;
  line-height: 20px;
  text-align: left;
`;

export const ButtonContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0px;
  max-width: 440px;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  line-height: 20px;
  text-align: left;
  max-width: 420px;
`;

export const WarningView = styled.div`
  display: inline-flex;
  background-color: #f9dad6;
  margin-left: 30px;
  padding: 12px;
  margin-bottom: 40px;
`;

export const WarningText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #000;
  margin-left: 10px;
  line-height: 20px;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const CustomRadio = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #949494;

  ${Input}:checked ~ & {
    background-color: #fff;
    border: 1px solid #ff7a14;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked ~ &::after {
    display: block;
  }

  &::after {
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ff7a14;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  * {
    box-sizing: border-box;
  }
`;

export const FlexRowActionContainer = styled(FlexRow)`
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  column-gap: 25px;
`;
