import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const PageDescription = styled.p`
  display: flex;
  font-size: 1rem;
  text-align: center;
`;

export const LabelAction = styled.p`
  display: flex;
  font-size: 1rem;
  margin-top: 20px;
`;

export const RedirectText = styled.p`
  display: flex;
  font-size: 1rem;
  color: ${(props) => props.theme.link.text};
  margin-top: 27px;
`;
