import Logo from "../../components/Logo";
import {
  Container,
  MiddleContainer,
  Title,
  Subtitle,
  CenteredButton,
  EnterContactView,
  ContactGroup,
  InputGroup,
  Input,
} from "./styles";
import ViewModel from "./viewModel";
import AppFooter from "../../components/Footer";
import DropDown from "../../components/DropDown";
import Spinner from "../../components/Spinner";
import ErrorModal from "../../components/ErrorModal";

const SendCode: React.FC = () => {
  const {
    t,
    isContactValid,
    codeSendLoading,
    countriesData,
    defaultCountry,
    setCallingCode,
    setContactNumber,
    sendCodeRequest,
    sendCodeApiError,
    clearSendCodeApiError,
    sendCodeApiValidationError,
    refSendCodeButton,
  } = ViewModel();
  return (
    <>
      <Container>
        <ErrorModal
          errorMessage={sendCodeApiError}
          onHide={() => clearSendCodeApiError()}
        />
        <Spinner visible={codeSendLoading} />
        <Logo title={t("screenHeaders.mobile_verification")} />
        <MiddleContainer>
          <Title>{t("ota.title")}</Title>
          <Subtitle>{t("ota.subtitle")}</Subtitle>

          <EnterContactView>
            <ContactGroup>
              <DropDown
                options={countriesData}
                defaultOption={defaultCountry}
                title={t("ota.cell_phone")}
                onDropdownChange={(value) => setCallingCode(`+${value}`)}
                dropdownKey="callingCode"
                prefix="+"
              />
            </ContactGroup>
            <InputGroup>
              <Input
                id={"contact"}
                data-qa-id=""
                label={""}
                required={true}
                readOnly={false}
                type={"text"}
                isError={sendCodeApiValidationError ? true : false}
                infoMessage={sendCodeApiValidationError?.error_message}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </InputGroup>
          </EnterContactView>
          <CenteredButton
            ref={refSendCodeButton}
            type="submit"
            variant="primary"
            id="Next"
            disabled={!isContactValid}
            onClick={() => sendCodeRequest(false)}
          >
            {t("common.send")}
          </CenteredButton>
        </MiddleContainer>
      </Container>
      <AppFooter />
    </>
  );
};

export default SendCode;
