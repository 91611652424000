import mixpanel from "mixpanel-browser";
import { MIXPANEL_TOKEN } from "../../config";

export const initializeMixpanel = () => {
  if (MIXPANEL_TOKEN) {
    mixpanel.init(MIXPANEL_TOKEN, { debug: true });
  }
};

export const trackEvent = (event: string, properties?: object) => {
  mixpanel.track(event, properties);
};
