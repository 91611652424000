import {
  ButtonContainer,
  WarningView,
  Subtitle,
  Label,
  Input,
  CustomRadio,
  FlexRowActionContainer,
  WarningText,
  Container,
} from "./styles";
import { KitButton } from "@chargepoint/cp-toolkit";
import Logo from "../../components/Logo";
import AppFooter from "../../components/Footer";
import ViewModel from "./viewModel";
import { warning } from "../../constant/ExternalIcons";
import SvgIcon from "../../components/SvgIcon";
import { COLORS } from "../../constant/colors";

const Introduction: React.FC = () => {
  const { t, selectedOption, handleOptionChange, moveToNextScreen } =
    ViewModel();
  return (
    <>
      <Container>
        <Logo title={t("screenHeaders.technician_registration")} />
        <Subtitle>{t("intro.subtitle")}</Subtitle>
        <ButtonContainer>
          <Label>
            <Input
              type="radio"
              name="option"
              value="technician"
              checked={selectedOption === "technician"}
              onChange={handleOptionChange}
            />
            <CustomRadio />
            {t("intro.option1")}
          </Label>
          <WarningView>
            <SvgIcon svgData={warning} size="30px" color={COLORS.warning} />
            <WarningText>{t("intro.warning")}</WarningText>
          </WarningView>
          <Label>
            <Input
              type="radio"
              name="option"
              value="driver"
              checked={selectedOption === "driver"}
              onChange={handleOptionChange}
            />
            <CustomRadio />
            {t("intro.option2")}
          </Label>
        </ButtonContainer>
        <FlexRowActionContainer>
          <KitButton
            type="button"
            variant="secondary"
            id="back"
            onClick={() => {
              window.history.back();
            }}
          >
            {t("common.back")}
          </KitButton>
          <KitButton
            type="button"
            variant="primary"
            id="Next"
            disabled={!selectedOption}
            onClick={() => moveToNextScreen()}
          >
            {t("common.next")}
          </KitButton>
        </FlexRowActionContainer>
      </Container>
      <AppFooter />
    </>
  );
};

export default Introduction;
