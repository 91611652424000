import React from "react";
import {
  CenteredContainer,
  CloseButton,
  Footer,
  FooterContent,
  FooterLink,
  FooterLinks,
  LanguageButton,
  LanguageList,
  LocalizationButton,
  ModalContainer,
  RegionContainer,
  RegionTitle,
  Title,
  TitleBar,
} from "./styles";
import { languageNames, regionWiseLanguages } from "../../constant";
import { KitIcon } from "@chargepoint/cp-toolkit";
import ViewModel from "./viewModel";
import SvgIcon from "../SvgIcon";
import { webIcon } from "../../constant/ExternalIcons";
import { ABOUT_US, CONTACT_US } from "../../constant/hyperlinks";
import { COLORS } from "../../constant/colors";

const AppFooter: React.FC = () => {
  const {
    t,
    isModalOpen,
    currentLanguage,
    handleLocalizationClick,
    handleModalClose,
    changeLanguage,
  } = ViewModel();
  return (
    <>
      <Footer>
        <FooterContent>
          <span>{t("footer.text")}</span>
          <FooterLinks>
            <LocalizationButton onClick={handleLocalizationClick}>
              <SvgIcon svgData={webIcon} size="20px" color={COLORS.webIcon} />
              <FooterLink>{currentLanguage}</FooterLink>
            </LocalizationButton>
            <FooterLink href={ABOUT_US}>{t("footer.aboutUs")}</FooterLink>
            <FooterLink href={CONTACT_US}>{t("footer.contactUs")}</FooterLink>
          </FooterLinks>
        </FooterContent>
      </Footer>
      {isModalOpen && (
        <>
          <CenteredContainer onClick={handleModalClose}>
            <ModalContainer>
              <TitleBar>
                <Title>{t("footer.selectLanguage")}</Title>
                <CloseButton onClick={handleModalClose}>
                  <KitIcon icon="close" size={"25px"} fill={COLORS.closeIcon} />
                </CloseButton>
              </TitleBar>
              {regionWiseLanguages.map((region) => (
                <RegionContainer key={region.title}>
                  <RegionTitle>{region.title}</RegionTitle>
                  <LanguageList>
                    {region.locals.map((local) => (
                      <LanguageButton
                        onClick={() => changeLanguage(local)}
                        key={local}
                      >
                        {languageNames[local]}
                      </LanguageButton>
                    ))}
                  </LanguageList>
                </RegionContainer>
              ))}
            </ModalContainer>
          </CenteredContainer>
        </>
      )}
    </>
  );
};

export default AppFooter;
