import { useState } from "react";
import { useTranslation } from "react-i18next";
import { languageNames } from "../../constant";
import { FooterViewModel } from "./type";

const ViewModel = (): FooterViewModel => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const currentLanguage = languageNames[i18n.language] || "English";

  const handleLocalizationClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    handleModalClose();
  };

  return {
    t,
    isModalOpen,
    currentLanguage,
    handleLocalizationClick,
    handleModalClose,
    changeLanguage,
  };
};
export default ViewModel;
