import styled from "styled-components";
import { KitButton } from "@chargepoint/cp-toolkit";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 15px;
    padding-bottom: 100px;
  }
`;

export const MiddleContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 10px;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 440px;
  width: 100%;
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #4c4c4c;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #4c4c4c;
`;

export const EnterCodeView = styled.div`
  width: 100%;
  max-width: 440px;
  gap: 10px;
  align-items: flex-start;
  flex-direction: row;
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 20px;
  & button {
    height: 38px !important;
    width: 38px !important;
  }
`;

export const CenteredButton = styled(KitButton)`
  margin: 50px 0px;
  align-self: center;
  background-color: #a2a11a;

  &.gYQL.link:focus {
    outline: 0px;
  }
`;

export const DualButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  width: 300px;
`;
