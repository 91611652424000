import { useTranslation } from "react-i18next";
import { SendCodeViewModel } from "./type";
import { useEffect, useState, useRef } from "react";
import useStore from "../../store/useStore";
import { isValidContactNumber } from "../../../utils";
import { useNavigate } from "react-router-dom";

const ViewModel = (): SendCodeViewModel => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [callingCode, setCallingCode] = useState<string>("");
  const [contactNumber, setContactNumber] = useState<string>("");
  const {
    codeSendLoading,
    globalConfigData,
    requestSendCode,
    sendCodeApiError,
    clearSendCodeApiError,
    sendCodeApiValidationError,
  } = useStore();
  const [isContactValid, setIsContactValid] = useState<boolean>(false);
  const refSendCodeButton = useRef<HTMLButtonElement>(null);

  const sendCodeRequest = () => {
    // Send code request
    requestSendCode(
      {
        country_calling_code: callingCode.substring(1),
        phone_number: contactNumber.replace(/\D/g, ""), // removing any special char and taking only numbers
      },
      () => navigate("/signup/verify-code")
    );
  };

  const countryCodeForCallingCode = (callingCode: string) => {
    if (globalConfigData?.supportedCountries) {
      return globalConfigData?.supportedCountries.find(
        (country: any) =>
          country.callingCode === parseInt(callingCode.substring(1))
      );
    }
  };

  useEffect(() => {
    setIsContactValid(
      isValidContactNumber(
        contactNumber,
        countryCodeForCallingCode(callingCode)?.code || ""
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactNumber, callingCode]);

  useEffect(() => {
    if (globalConfigData?.defaultCountry) {
      setCallingCode(`+${globalConfigData?.defaultCountry?.callingCode}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sendCodeButtonRef = refSendCodeButton?.current;
    if (globalConfigData?.defaultCountry) {
      setCallingCode(`+${globalConfigData?.defaultCountry?.callingCode}`);
    }

    // TO TRIGGER THE API ONCES CAPTCHA IS SUCCESSFUL
    window.addEventListener("dd_response_passed", () =>
      sendCodeButtonRef?.click()
    );

    return () =>
      window.removeEventListener("dd_response_passed", () =>
        sendCodeButtonRef?.click()
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    t,
    isContactValid,
    codeSendLoading,
    countriesData: globalConfigData?.supportedCountries,
    defaultCountry: globalConfigData?.defaultCountry,
    setCallingCode,
    setContactNumber,
    sendCodeRequest,
    sendCodeApiError,
    clearSendCodeApiError,
    sendCodeApiValidationError,
    refSendCodeButton,
  };
};
export default ViewModel;
