import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useStore from "../../store/useStore";
import { VERIFICATION_CODE_COUNT } from "../../constant";
import { useNavigate } from "react-router-dom";
import { VerifyCodeViewModel } from "./type";

const ViewModel = (): VerifyCodeViewModel => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [enteredCode, setEnteredCode] = useState<string>("");
  const [codeResent, setCodeResent] = useState(false);
  const {
    codeSendLoading,
    codeVerifyLoading,
    userDetails,
    contactInformation,
    requestSendCode,
    requestVerifyCode,
    verifyCodeApiError,
    clearVerifyCodeApiError,
    sendCodeApiError,
    sendCodeApiValidationError,
    clearSendCodeApiError,
    verifyCodeApiValidationError,
    clearVerifyCodeApiValidationError,
    clearSendCodeApiValidationError,
  } = useStore();

  const resendCodeRequest = () => {
    // Resend code request
    requestSendCode(contactInformation, () => setCodeResent(true));
  };

  const verifyCodeRequest = () => {
    // Verify code request
    if (enteredCode.length === VERIFICATION_CODE_COUNT) {
      const params = {
        ...userDetails,
        ...contactInformation,
        verification_code: enteredCode,
      };
      clearSendCodeApiValidationError();
      requestVerifyCode(params, () => navigate("/signup/complete"));
    }
  };

  const backHandler = () => {
    navigate(-1);
  };

  const setInfoMessage = useMemo(() => {
    if (sendCodeApiValidationError) {
      return sendCodeApiValidationError?.error_message;
    } else if (verifyCodeApiValidationError) {
      return verifyCodeApiValidationError?.error_message;
    } else if (codeResent) {
      return t("ota.code_resent");
    }
  }, [codeResent, sendCodeApiValidationError, verifyCodeApiValidationError, t]);

  return {
    t,
    enteredCode,
    contactInformation,
    codeResent,
    codeSendLoading,
    codeVerifyLoading,
    setEnteredCode,
    resendCodeRequest,
    verifyCodeRequest,
    backHandler,
    setInfoMessage,
    verifyCodeApiError,
    clearVerifyCodeApiError,
    sendCodeApiError,
    sendCodeApiValidationError,
    clearSendCodeApiError,
    verifyCodeApiValidationError,
    clearVerifyCodeApiValidationError,
  };
};
export default ViewModel;
