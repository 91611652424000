import { useEffect } from "react";
import useStore from "../../store/useStore";
import { DEFAULT_REDIRECT_URL } from "../../../utils/constants";

const ViewModel = () => {
  const { redirectUrl, clearSignUpUserDetails } = useStore();

  useEffect(() => {
    const redirectToUrl = redirectUrl || DEFAULT_REDIRECT_URL;

    const timer = setTimeout(() => {
      clearSignUpUserDetails();
      window.location.replace(redirectToUrl);
    }, 4000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl]);

  const onClickRedirect = () => {
    const redirectToUrl = redirectUrl || DEFAULT_REDIRECT_URL;
    clearSignUpUserDetails();
    window.location.replace(redirectToUrl);
  };

  return { onClickRedirect };
};

export default ViewModel;
