export const REGULAR_EXPRESSION = {
  password:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[A-Z])(?=.*\d)(?=.*[-=|+!~^{}\[\]%<>`.'$@&_:;/?*#()\\])[A-Za-z\d-=|+!~^{}\[\]%<>`.'$@&_:;/?*#()\\]{8,64}$/,
  email:
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  name: /^[a-zA-Z ]*$/,
  lastName: /^[a-zA-Z ]*$/,
  username: /^[A-Za-z0-9_.]+$/,
};

export const MIN_USERNAME_LENGTH = 6;
export const MAX_USERNAME_LENGTH = 32;
export const PASSWORD_LENGTH = 8;
export const TERMS_AND_CONDITIONS = "termsOfService";
export const PRIVACY_POLICY = "privacyPolicy";

export const languageNames: { [key: string]: string } = {
  enUS: "English",
  enGB: "English",
  enCA: "English",
  enAU: "English",
  esLA: "Español",
  esES: "Español",
  frCA: "Français",
  frFR: "Français",
  deDE: "Deutsch",
  itIT: "Italiano",
  nlNL: "Nederlands",
  ptPT: "Português",
  svSE: "Svenska",
  csCZ: "čeština",
  daDK: "Dansk",
  nbNO: "Norsk Bokmål",
  plPL: "Polskie",
};

export const regionWiseLanguages = [
  {
    title: "United States",
    locals: ["enUS", "esES", "frCA"],
  },
  {
    title: "Canada",
    locals: ["enUS", "frCA"],
  },
  {
    title: "Europe",
    locals: [
      "csCZ",
      "daDK",
      "deDE",
      "esES",
      "frFR",
      "itIT",
      "nlNL",
      "nbNO",
      "plPL",
      "ptPT",
      "svSE",
    ],
  },
  {
    title: "Asia",
    locals: ["enUS"],
  },
];

export const VERIFICATION_CODE_COUNT = 4;
