import React from "react";
import { KitForm, KitCheck, KitButton } from "@chargepoint/cp-toolkit";
import {
  FlexRowActionContainer,
  PolicyText,
  FlexRowPolicy,
  Container,
  KitFormStyled,
  VisibilityIcon,
  VisibilityOffIcon,
  HaveAgreeText,
  Div,
  KitFormRow,
  KitFormFirstAndLastName,
} from "./styles";
import DropDown from "../../components/DropDown";
import Logo from "../../components/Logo";
import ViewModel from "./viewModel";
import AppFooter from "../../components/Footer";
import Spinner from "../../components/Spinner";
import ErrorModal from "../../components/ErrorModal";

const SignUp: React.FC = () => {
  const {
    t,
    isPrivacyPolicyChecked,
    setIsPrivacyPolicyChecked,
    handleSubmit,
    register,
    errors,
    isNextEnable,
    privacyPolicyUrl,
    termAndConditionUrl,
    globalConfigData,
    hidePassword,
    setHidePassword,
    hideConfirmPassword,
    setHideConfirmPassword,
    onDropdownChange,
    saveAccountInfo,
    backHandler,
    loading,
    signupLoading,
    signUpApiError,
    clearSignUpApiError,
    refNextButton,
  } = ViewModel();

  return (
    <>
      <Container>
        <ErrorModal
          errorMessage={signUpApiError}
          onHide={() => clearSignUpApiError()}
        />
        <Spinner visible={loading || signupLoading} />
        <Logo title={t("sign_up.create_field_technician_account")} />
        <KitFormStyled onSubmit={handleSubmit(saveAccountInfo)}>
          <KitFormRow>
            <KitFormFirstAndLastName>
              <KitForm.Input
                id={"first_name"}
                data-qa-id=""
                label={t("sign_up.first_name")}
                required={true}
                readOnly={false}
                isError={!!errors.first_name}
                {...register("first_name")}
                infoMessage={errors.first_name?.message}
              />
            </KitFormFirstAndLastName>
            <KitFormFirstAndLastName>
              <KitForm.Input
                id={"last_name"}
                data-qa-id=""
                label={t("sign_up.last_name")}
                required={true}
                readOnly={false}
                isError={!!errors.last_name}
                {...register("last_name")}
                infoMessage={errors.last_name?.message}
              />
            </KitFormFirstAndLastName>
          </KitFormRow>
          <KitForm.Group>
            <KitForm.Input
              id={"email"}
              data-qa-id=""
              label={t("sign_up.email")}
              required={true}
              readOnly={false}
              isError={!!errors.email}
              {...register("email")}
              infoMessage={errors.email?.message}
            />
          </KitForm.Group>
          <KitForm.Group>
            <KitForm.Input
              id={"username"}
              data-qa-id=""
              label={t("sign_up.username")}
              required={true}
              readOnly={false}
              isError={!!errors.username}
              {...register("username")}
              infoMessage={errors.username?.message}
            />
          </KitForm.Group>

          <KitForm.Group>
            <Div onClick={() => setHidePassword(!hidePassword)}>
              {hidePassword ? (
                <VisibilityIcon fontSize="small" />
              ) : (
                <VisibilityOffIcon fontSize="small" />
              )}
            </Div>
            <KitForm.Input
              id={"password"}
              data-qa-id=""
              label={t("sign_up.password")}
              required={true}
              readOnly={false}
              isError={!!errors.password}
              {...register("password")}
              type={hidePassword ? "password" : "text"}
              infoMessage={errors.password?.message}
            />
          </KitForm.Group>

          <KitForm.Group>
            <Div onClick={() => setHideConfirmPassword(!hideConfirmPassword)}>
              {hideConfirmPassword ? (
                <VisibilityIcon fontSize="small" />
              ) : (
                <VisibilityOffIcon fontSize="small" />
              )}
            </Div>
            <KitForm.Input
              id={"confirm_password"}
              data-qa-id=""
              label={t("sign_up.confirm_password")}
              required={true}
              readOnly={false}
              isError={!!errors.confirm_password}
              {...register("confirm_password")}
              type={hideConfirmPassword ? "password" : "text"}
              infoMessage={errors.confirm_password?.message}
            />
          </KitForm.Group>

          {globalConfigData?.supportedCountries ? (
            <KitForm.Group>
              <DropDown
                options={globalConfigData?.supportedCountries}
                defaultOption={globalConfigData?.defaultCountry}
                title={t("sign_up.country")}
                onDropdownChange={onDropdownChange}
                dropdownKey={"name"}
              />
            </KitForm.Group>
          ) : (
            <></>
          )}

          <KitForm.Group>
            <FlexRowPolicy>
              <KitCheck
                id="showHome"
                labelDataQaId="showHome"
                checked={isPrivacyPolicyChecked}
                onChange={() => {
                  setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked);
                }}
              />
              <HaveAgreeText>
                {t("sign_up.agree")}{" "}
                <PolicyText
                  target="_blank"
                  rel="noopener noreferrer"
                  href={privacyPolicyUrl}
                >
                  {t("sign_up.privacy_policy")}
                </PolicyText>
                <HaveAgreeText>
                  {"  "}
                  {t("common.and")}
                  {"  "}
                </HaveAgreeText>
                <PolicyText
                  target="_blank"
                  rel="noopener noreferrer"
                  href={termAndConditionUrl}
                >
                  {t("sign_up.terms_and_service")}
                </PolicyText>
              </HaveAgreeText>
            </FlexRowPolicy>
          </KitForm.Group>

          <FlexRowActionContainer>
            <KitButton
              type="button"
              variant="secondary"
              id="back"
              onClick={() => {
                backHandler();
              }}
            >
              {t("common.back")}
            </KitButton>
            <KitButton
              ref={refNextButton}
              type="submit"
              variant="primary"
              id="Next"
              disabled={!isNextEnable}
            >
              {t("common.next")}
            </KitButton>
          </FlexRowActionContainer>
        </KitFormStyled>
      </Container>
      <AppFooter />
    </>
  );
};

export default SignUp;
