import styled from "styled-components";
import { KitForm } from "@chargepoint/cp-toolkit";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: center;

  @media (max-width: 768px) {
    padding: 15px;
    padding-bottom: 100px;
  }
`;

export const KitFormStyled = styled(KitForm)`
  @media only screen and (min-width: 500px) {
    width: 500px;
  }
  padding: 25px;
`;

export const HaveAgreeText = styled.a`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.checkbox.label};
`;

export const PolicyText = styled.a`
  font-size: 0.9rem;
  color: ${(props) => props.theme.link.text};
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  * {
    box-sizing: border-box;
  }
  align-items: center;
`;

export const FlexRowPolicy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const FlexRowActionContainer = styled(FlexRow)`
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  column-gap: 25px;
`;

export const VisibilityIcon = styled(Visibility)`
  position: absolute;
`;

export const VisibilityOffIcon = styled(VisibilityOff)`
  position: absolute;
`;

export const Div = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  top: 36.5px;
`;

export const KitFormRow = styled(KitForm.Group)`
  display: flex;
  flex-direction: row;
  column-gap: 25px;
`;

export const KitFormFirstAndLastName = styled(KitForm.Group)`
  width: 48%;
`;
