export const dynamicString = (
  input: string,
  ...replacements: string[]
): string => input.replace(/\{0\}/g, replacements.join(" "));

// Validate phone number
export const isValidContactNumber = (number: string, countryCode: string) => {
  const phoneUtil =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();

  try {
    const parsedNumber = phoneUtil.parse(number, countryCode);

    return phoneUtil.isValidNumber(parsedNumber);
  } catch (err) {
    return false;
  }
};
