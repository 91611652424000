import { FC } from "react";
import styled from "styled-components";
import CPLogo from "../../assets/cp_logo.png";

export interface LogoProps {
  title: string;
  alt?: string;
}

const Logo: FC<LogoProps> = ({ title, alt }: LogoProps) => {
  return (
    <InstallerAccountContainer>
      <img src={CPLogo} alt={alt} style={{ width: "auto", height: "40px" }} />
      <InstallerAccount>{title}</InstallerAccount>
    </InstallerAccountContainer>
  );
};

const InstallerAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  align-items: center;
  justify-content: center;
  row-gap: 25px;
  margin-top: 20px;
`;

const InstallerAccount = styled.p`
  font-size: 22px;
  color: #000000;
  text-align: center;
`;

export default Logo;
