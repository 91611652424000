import styled from "styled-components";

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 10px 20px;
  background-color: #f1f1f1;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid #bfbfbf;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LocalizationButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
  }
`;

export const FooterLink = styled.a`
  color: ${(props) => props.theme.link.text};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// Language selector styled components
export const CenteredContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Dim background */
  z-index: 1000; /* Ensures it floats above other content */
`;

export const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  max-width: 500px;
  max-height: 70vh;
  overflow-y: auto;
  z-index: 1001;

  @media (max-width: 768px) {
    max-width: 90%;
    max-height: 80vh;
  }
`;

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 25px;
  padding: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.h5`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 26px;
  cursor: pointer;
`;

export const RegionContainer = styled.div`
  margin-bottom: 20px;
  padding: 0px 20px 0px 20px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const RegionTitle = styled.h3`
  margin: 0 0 10px 0;
  font-size: 18px;
`;

export const LanguageList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const LanguageButton = styled.button`
  background: white;
  outline: 0;
  border: 0;
  color: ${(props) => props.theme.link.text};
  margin: 5px 5px;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 6px 12px;
  white-space: nowrap;
  transition: ease background-color 250ms;
  text-decoration: none;
  &:hover {
    background: #eee;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;
