import styled from "styled-components";

export default function DropDown({
  options,
  defaultOption,
  title,
  onDropdownChange,
  dropdownKey,
  prefix,
}: {
  options: any[];
  defaultOption: any;
  title: string;
  onDropdownChange: (value: any) => void;
  dropdownKey: string;
  prefix?: string;
}) {
  return (
    <>
      <FormLabel focused={true}>{title}</FormLabel>
      <DropDownSelectContainer
        name={title}
        defaultValue={defaultOption[dropdownKey]}
        onChange={(value) => onDropdownChange(value.target.value)}
      >
        {options.map((option) => (
          <option key={option?.id} value={option[dropdownKey]}>
            {prefix}
            {option[dropdownKey]}
          </option>
        ))}
      </DropDownSelectContainer>
      <DropDownLine />
    </>
  );
}

const DropDownSelectContainer = styled("select")`
  width: 100%;
  border: 0;
  background-color: transparent;
`;

const DropDownLine = styled("div")`
  width: 100%;
  border-bottom-color: ${({ theme }) => theme.input.normal.bottomBorder};
  border-bottom-width: 2px;
  border-bottom-style: solid;
  margin-top: 10px;
`;

const FormLabel = styled.label<{
  focused: boolean;
}>`
  background-color: inherit;
  color: ${({ theme }) => theme.label};
  display: block;
  font-size: 0.875rem;
  font-weight: ${({ focused }) => (focused ? 500 : 400)};
  margin-bottom: 24px;
`;
