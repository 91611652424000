import React from "react";
import {
  PageDescription,
  Container,
  LabelAction,
  RedirectText,
} from "./styles";
import Logo from "../../components/Logo";
import { useTranslation } from "react-i18next";
import { KitSpinner } from "@chargepoint/cp-toolkit";
import ViewModel from "./ViewModel";
import AppFooter from "../../components/Footer";

const RedirectScreen: React.FC = () => {
  const { t } = useTranslation();
  const { onClickRedirect } = ViewModel();

  return (
    <>
      <Container>
        <Logo title={t("screenHeaders.redirect")} />
        <PageDescription>{t("screenDescription.redirect")}</PageDescription>
        <KitSpinner />
        <LabelAction>{t("labels.redirecting")}</LabelAction>
        <RedirectText onClick={onClickRedirect}>
          {t("labels.redirectingContent")}
        </RedirectText>
      </Container>
      <AppFooter />
    </>
  );
};

export default RedirectScreen;
