import * as Yup from "yup";
import { TFunction } from "i18next";
import {
  REGULAR_EXPRESSION,
  MIN_USERNAME_LENGTH,
  PASSWORD_LENGTH,
  MAX_USERNAME_LENGTH,
} from "../../constant";

export interface AccountInfoModel {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  password: string;
  confirm_password: string;
}

export const getValidationSchema = (
  t: TFunction
): Yup.SchemaOf<AccountInfoModel> => {
  return Yup.object({
    first_name: Yup.string()
      .required(
        t("common.field_is_required", {
          field_name: t("sign_up.first_name"),
        })
      )
      .matches(REGULAR_EXPRESSION.name, t("signup_error_messages.first_name")),
    last_name: Yup.string()
      .required(
        t("common.field_is_required", {
          field_name: t("sign_up.last_name"),
        })
      )
      .matches(
        REGULAR_EXPRESSION.lastName,
        t("signup_error_messages.last_name")
      ),
    email: Yup.string()
      .required(
        t("common.field_is_required", {
          field_name: t("sign_up.email"),
        })
      )
      .matches(
        REGULAR_EXPRESSION.email,
        t("signup_error_messages.email_validate", {
          field_name: t("sign_up.email"),
        })
      ),
    username: Yup.string()
      .required(
        t("common.field_is_required", {
          field_name: t("sign_up.username"),
        })
      )
      .min(
        MIN_USERNAME_LENGTH,
        t("signup_error_messages.user_name_min", {
          min_username: MIN_USERNAME_LENGTH,
        })
      )
      .max(
        MAX_USERNAME_LENGTH,
        t("signup_error_messages.user_name_max", {
          max_username: MAX_USERNAME_LENGTH,
        })
      )
      .matches(
        REGULAR_EXPRESSION.username,
        t("signup_error_messages.user_name_validate")
      ),
    password: Yup.string()
      .required(
        t("common.field_is_required", {
          field_name: t("sign_up.password"),
        })
      )
      .min(
        PASSWORD_LENGTH,
        t("signup_error_messages.min_password_length", {
          min_password: PASSWORD_LENGTH,
        })
      )
      .matches(
        REGULAR_EXPRESSION.password,
        t("signup_error_messages.password_validate")
      ),
    confirm_password: Yup.string()
      .required(
        t("common.field_is_required", {
          field_name: t("sign_up.confirm_password"),
        })
      )
      .min(
        PASSWORD_LENGTH,
        t("signup_error_messages.min_password_length", {
          min_password: PASSWORD_LENGTH,
        })
      )
      .matches(
        REGULAR_EXPRESSION.password,
        t("signup_error_messages.password_validate")
      )
      .oneOf(
        [Yup.ref("password"), null],
        t("signup_error_messages.passwords_do_not_match")
      ),
  });
};
