import { KitModalSize, KitModal } from "@chargepoint/cp-toolkit";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ErrorModal = ({
  onHide,
  errorMessage,
}: {
  onHide: () => void;
  errorMessage: string | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <KitModal
      show={errorMessage ? true : false}
      onHide={onHide}
      size={KitModalSize.md}
      position="center"
    >
      <KitModal.Header closeButton t={t}>
        <KitModal.Title>{t("labels.error")}</KitModal.Title>
      </KitModal.Header>
      <KitModal.Body>
        <ErrorText>{errorMessage}</ErrorText>
      </KitModal.Body>
    </KitModal>
  );
};

export const ErrorText = styled.a`
  font-size: 0.9rem;
  color: ${(props) => props.theme.info.text};
`;

export default ErrorModal;
