import styled from "styled-components";

const IconWrapper = styled.div<{ size?: string; color?: string }>`
  width: ${(props) => props.size || "24px"};
  height: ${(props) => props.size || "24px"};
  display: inline-block;
  svg {
    fill: ${(props) => props.color || "currentColor"};
    width: 100%;
    height: 100%;
  }
`;

const SvgIcon = ({
  svgData,
  size,
  color,
  ...props
}: {
  svgData: string;
  size?: string;
  color?: string;
}) => {
  return (
    <IconWrapper
      size={size}
      color={color}
      {...props}
      dangerouslySetInnerHTML={{ __html: svgData }}
    />
  );
};

export default SvgIcon;
