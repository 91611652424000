import { StateCreator } from "zustand";
import { apiClientSSO } from "../../api/baseApi";
import { ApiError } from "./appSlice";

export interface ContactInformation {
  country_calling_code: string;
  phone_number: string;
}
export interface SendCodeState {
  contactInformation: ContactInformation;
  codeSendLoading: boolean;
  sendCodeApiError: string | null;
  sendCodeApiValidationError: ApiError | null;
  clearSendCodeApiError: () => void;
  clearSendCodeApiValidationError: () => void;
  requestSendCode: (
    info: ContactInformation,
    successCallback: () => void
  ) => Promise<void>;
}

export interface VerifyCodeState {
  codeVerifyLoading: boolean;
  verifyCodeApiError: string | null;
  verifyCodeApiValidationError: ApiError | null;
  clearVerifyCodeApiError: () => void;
  clearVerifyCodeApiValidationError: () => void;
  requestVerifyCode: (
    params: any,
    successCallback: () => void
  ) => Promise<void>;
}

export const createSendCodeSlice: StateCreator<SendCodeState> = (set) => ({
  contactInformation: {
    country_calling_code: "",
    phone_number: "",
  },
  codeSendLoading: false,
  sendCodeApiError: null,
  sendCodeApiValidationError: null,

  clearSendCodeApiError: () => {
    set({ sendCodeApiError: null });
  },

  clearSendCodeApiValidationError: () => {
    set({ sendCodeApiValidationError: null });
  },

  requestSendCode: async (
    info: ContactInformation,
    successCallback: () => void
  ) => {
    set({
      codeSendLoading: true,
      sendCodeApiError: null,
      sendCodeApiValidationError: null,
    });
    try {
      const result = await apiClientSSO.post<[]>("v1/user/sendcode", info);
      if (result.status === 200) {
        set({
          contactInformation: info,
        });
        successCallback();
      }
    } catch (error: any) {
      const errorData = error?.response?.data;
      if (errorData?.error_message) {
        set({
          sendCodeApiValidationError: errorData,
        });
      } else {
        set({
          sendCodeApiError: (error as Error).message,
        });
      }
    } finally {
      set({
        codeSendLoading: false,
      });
    }
  },
});

export const createVerifyCodeSlice: StateCreator<VerifyCodeState> = (set) => ({
  codeVerifyLoading: false,
  verifyCodeApiError: null,
  verifyCodeApiValidationError: null,

  clearVerifyCodeApiError: () => {
    set({ verifyCodeApiError: null });
  },

  clearVerifyCodeApiValidationError: () => {
    set({ verifyCodeApiValidationError: null });
  },

  requestVerifyCode: async (params: any, successCallback: () => void) => {
    set({
      codeVerifyLoading: true,
      verifyCodeApiError: null,
      verifyCodeApiValidationError: null,
    });
    try {
      const response = await apiClientSSO.post<[]>("v1/admin", params);
      if (response.status === 200 || response.status === 201) {
        successCallback();
      }
    } catch (error: any) {
      const errorData = error?.response?.data?.error;
      if (Array.isArray(errorData)) {
        set({
          verifyCodeApiValidationError: errorData[0],
        });
      } else {
        set({
          verifyCodeApiError: (error as Error).message,
        });
      }
    } finally {
      set({
        codeVerifyLoading: false,
      });
    }
  },
});
