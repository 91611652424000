import { create } from "zustand";
import {
  globalConfigSlice,
  // GlobalConfigState,
  signupSlice,
  // SignupState,
} from "./slices/appSlice";
import {
  createSendCodeSlice,
  createVerifyCodeSlice,
  // SendCodeState,
  // VerifyCodeState,
} from "./slices/contactVerificationSlice";
import { persist } from "zustand/middleware";

// type StoreState = GlobalConfigState &
//   SignupState &
//   SendCodeState &
//   VerifyCodeState;

const useStore = create<any>(
  persist(
    (set, get, api) => ({
      ...globalConfigSlice(set, get, api),
      ...signupSlice(set, get, api),
      ...createSendCodeSlice(set, get, api),
      ...createVerifyCodeSlice(set, get, api),
    }),
    {
      name: "app-storage", // name of the item in the storage (must be unique)
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useStore;
