import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Navigation from "./app/navigation";
import { initializeMixpanel } from "./app/mixpanel/mixpanelConfig";
import * as Sentry from "@sentry/react";
import { APP_ENV, SENTRY_DSN } from "./config";
import { ThemeProvider } from "styled-components";
import {
  cpLightTheme,
  ThemeSchema,
  KitGlobalStyles,
} from "@chargepoint/cp-toolkit";
import "./i18n";

// Mixpanel initialization
initializeMixpanel();

// Sentry initialization
Sentry.init({
  environment: APP_ENV,
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const themeCPLight: ThemeSchema = { ...cpLightTheme };
root.render(
  <React.StrictMode>
    <ThemeProvider theme={themeCPLight}>
      <Navigation />
      <KitGlobalStyles rootId={"root"} />
    </ThemeProvider>
  </React.StrictMode>
);
