import { StateCreator } from "zustand";
import { apiClient, apiClientSSO } from "../../api/baseApi";

export interface GlobalConfigState {
  globalConfigData: any | null;
  loading: boolean;
  error: string | null;
  redirectUrl: string;
  fetchGlobalConfigData: (params: any) => Promise<void>;
  setRedirectUrl: (url: string) => void;
}
export interface SignupState {
  signupLoading: boolean;
  signUpApiError: string | null;
  userDetails: UserDetails;
  clearSignUpUserDetails: () => void;
  clearSignUpApiError: () => void;
  fetchAdminValidate: (
    params: UserDetails,
    successCallback: () => void,
    errorCallback: (error: ApiError) => void
  ) => Promise<void>;
}

export interface ApiError {
  error_category: string;
  error_field:
    | "first_name"
    | "last_name"
    | "email"
    | "username"
    | "password"
    | "confirm_password"
    | "root"
    | `root.${string}`;
  error_id: number;
  error_message: string;
}

export interface UserDetails {
  first_name: string;
  last_name: string;
  username: string;
  password: string;
  country: string;
  email: string;
}

export const globalConfigSlice: StateCreator<GlobalConfigState> = (set) => ({
  globalConfigData: {
    localizedResources: "",
    userAgreements: [],
    defaultCountry: {},
  },
  loading: false,
  error: null,
  redirectUrl: "",
  setRedirectUrl: (url: string) => {
    set({ redirectUrl: url });
  },
  fetchGlobalConfigData: async (params: any) => {
    set({ loading: true, error: null });
    try {
      const response = await apiClient.post<[]>("v1/globalconfig", params);
      set({ globalConfigData: response.data, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
});

export const signupSlice: StateCreator<SignupState> = (set) => ({
  signupLoading: false,
  signUpApiError: null,
  userDetails: {
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    country: "",
    email: "",
  },

  clearSignUpUserDetails: () => {
    set({
      userDetails: {
        first_name: "",
        last_name: "",
        username: "",
        password: "",
        country: "",
        email: "",
      },
    });
  },

  clearSignUpApiError: () => {
    set({ signUpApiError: null });
  },

  fetchAdminValidate: async (
    params: UserDetails,
    successCallback: () => void,
    errorCallback: (error: ApiError) => void
  ) => {
    set({
      signupLoading: true,
      signUpApiError: null,
    });
    try {
      const response = await apiClientSSO.post<[]>("v1/admin/validate", params);
      if (response.status === 200) {
        successCallback();
        set({
          userDetails: params,
        });
      }
    } catch (error: any) {
      const errorData = error?.response?.data?.error;
      if (
        Array.isArray(errorData) &&
        params.hasOwnProperty(errorData[0]?.error_field)
      ) {
        errorCallback(errorData[0]);
      } else {
        set({ signUpApiError: (error as Error).message });
      }
    } finally {
      set({
        signupLoading: false,
      });
    }
  },
});
