// src/api/apiClient.ts
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { trackEvent } from "../mixpanel/mixpanelConfig";
import { DISCOVERY_BASE_URL, REACT_APP_SSO_BASE_URL } from "../../config";

//Enable the cookie on all requests
axios.defaults.withCredentials = true;

// Set up the base URL if needed
const BASE_URL = DISCOVERY_BASE_URL; // Replace with actual base URL
const SSO_BASE_URL = REACT_APP_SSO_BASE_URL;

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiClientSSO = axios.create({
  baseURL: SSO_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to track API requests
apiClient.interceptors.request.use(
  (config) => {
    // Track the request start
    trackEvent("API Request Started", {
      url: config.url,
      method: config.method,
    });
    return config;
  },
  (error) => {
    // Track the request error
    trackEvent("API Request Error", { error: error.message });
    return Promise.reject(error);
  }
);

// Add a response interceptor to track API responses
apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // Track the successful response
    trackEvent("API Response Received", {
      url: response.config.url,
      method: response.config.method,
      status: response.status,
    });
    return response;
  },
  (error) => {
    // Track the response error
    trackEvent("API Response Error", {
      url: error.config?.url,
      method: error.config?.method,
      error: error.message,
    });
    return Promise.reject(error);
  }
);

// Add a request interceptor to track API requests
apiClientSSO.interceptors.request.use(
  (config) => {
    // Track the request start
    trackEvent("API Request Started", {
      url: config.url,
      method: config.method,
    });
    return config;
  },
  (error) => {
    // Track the request error
    trackEvent("API Request Error", { error: error.message });
    return Promise.reject(error);
  }
);

// Add a response interceptor to track API responses
apiClientSSO.interceptors.response.use(
  (response: AxiosResponse) => {
    // Track the successful response
    trackEvent("API Response Received", {
      url: response.config.url,
      method: response.config.method,
      status: response.status,
    });
    return response;
  },
  (error) => {
    // Track the response error
    trackEvent("API Response Error", {
      url: error.config?.url,
      method: error.config?.method,
      error: error.message,
    });
    return Promise.reject(error);
  }
);

export const get = (url: string, config?: AxiosRequestConfig) =>
  apiClient.get(url, config);
export const post = (url: string, data?: any, config?: AxiosRequestConfig) =>
  apiClient.post(url, data, config);

export const getSSO = (url: string, config?: AxiosRequestConfig) =>
  apiClientSSO.get(url, config);
export const postSSO = (url: string, data?: any, config?: AxiosRequestConfig) =>
  apiClientSSO.post(url, data, config);

export { apiClient, apiClientSSO };
