import { KitButton, KitForm } from "@chargepoint/cp-toolkit";
import Logo from "../../components/Logo";
import {
  Container,
  MiddleContainer,
  Title,
  Subtitle,
  CenteredButton,
  DualButtonContainer,
  EnterCodeView,
} from "./styles";
import ViewModel from "./viewModel";
import AppFooter from "../../components/Footer";
import { dynamicString } from "../../../utils";
import Spinner from "../../components/Spinner";
import ErrorModal from "../../components/ErrorModal";

const VerifyCode: React.FC = () => {
  const {
    t,
    enteredCode,
    contactInformation,
    codeVerifyLoading,
    setEnteredCode,
    resendCodeRequest,
    verifyCodeRequest,
    backHandler,
    verifyCodeApiError,
    clearVerifyCodeApiError,
    sendCodeApiError,
    sendCodeApiValidationError,
    clearSendCodeApiError,
    verifyCodeApiValidationError,
    setInfoMessage,
    clearVerifyCodeApiValidationError,
  } = ViewModel();
  return (
    <>
      <Container>
        <ErrorModal
          errorMessage={verifyCodeApiError || sendCodeApiError}
          onHide={() => {
            clearVerifyCodeApiError();
            clearSendCodeApiError();
          }}
        />
        <Spinner visible={codeVerifyLoading} />
        <Logo title={t("screenHeaders.mobile_verification")} />
        <MiddleContainer>
          <Title> {t("ota.title2")} </Title>
          <Subtitle>
            {dynamicString(
              t("ota.subtitle2"),
              Object.values(contactInformation).join(" ")
            )}
          </Subtitle>

          <EnterCodeView>
            <KitForm.Group>
              <KitForm.Input
                id={"number"}
                data-qa-id=""
                label={t("ota.enter_code")}
                required={false}
                readOnly={false}
                isError={
                  sendCodeApiValidationError?.error_message ||
                  verifyCodeApiValidationError?.error_message
                    ? true
                    : false
                }
                infoMessage={setInfoMessage}
                onChange={(e) => setEnteredCode(e.target.value)}
              />
            </KitForm.Group>
          </EnterCodeView>
          <CenteredButton
            variant="link"
            onClick={() => {
              resendCodeRequest();
              clearVerifyCodeApiValidationError();
            }}
          >
            {t("common.send_again")}
          </CenteredButton>
          <DualButtonContainer>
            <KitButton
              type="button"
              variant="secondary"
              id="back"
              onClick={() => backHandler()}
            >
              {t("common.back")}
            </KitButton>
            <KitButton
              type="button"
              variant="primary"
              id="Next"
              disabled={!enteredCode}
              onClick={() => verifyCodeRequest()}
            >
              {t("common.next")}
            </KitButton>
          </DualButtonContainer>
        </MiddleContainer>
      </Container>
      <AppFooter />
    </>
  );
};

export default VerifyCode;
