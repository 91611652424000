import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntoViewModel } from "./type";
import useStore from "../../store/useStore";
import { useNavigate } from "react-router-dom";
import { DRIVER_SIGNUP_URL } from "../../../config";

const ViewModel = (): IntoViewModel => {
  const { setRedirectUrl, fetchGlobalConfigData, globalConfigData } =
    useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string>("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getGlobalConfig(), []);

  const getGlobalConfig = () => {
    fetchGlobalConfigData(
      globalConfigData?.defaultCountry
        ? { countryCode: globalConfigData?.defaultCountry?.code }
        : {}
    );
  };

  useEffect(() => {
    const fullUrl = window.location.href;
    // Extract the `redirect` query parameter and everything after it
    const redirectParamMatch = fullUrl.match(/redirect=([^&]*&?.*)/);

    if (redirectParamMatch) {
      const encodedRedirectURL = redirectParamMatch[1];

      const decodedRedirectURL = decodeURIComponent(encodedRedirectURL);

      setRedirectUrl(decodedRedirectURL);
    }
  }, [setRedirectUrl]);

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const moveToNextScreen = () => {
    if (selectedOption === "technician") {
      navigate("/signup/create-account");
    } else {
      DRIVER_SIGNUP_URL && window.location.replace(DRIVER_SIGNUP_URL);
    }
  };

  return {
    t,
    selectedOption,
    handleOptionChange,
    moveToNextScreen,
  };
};
export default ViewModel;
