import { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getValidationSchema } from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useStore from "../../store/useStore";
import { TERMS_AND_CONDITIONS, PRIVACY_POLICY } from "../../constant";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../store/slices/appSlice";

const ViewModel = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] =
    useState<boolean>(false);
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const validationSchema = getValidationSchema(t);
  const {
    globalConfigData,
    fetchGlobalConfigData,
    fetchAdminValidate,
    loading,
    signupLoading,
    signUpApiError,
    clearSignUpApiError,
    userDetails,
  } = useStore();
  const refNextButton = useRef<HTMLButtonElement>(null);

  const {
    register,
    handleSubmit,
    setError,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      first_name: `${userDetails?.first_name ?? ""}`,
      last_name: `${userDetails?.last_name ?? ""}`,
      email: `${userDetails?.email ?? ""}`,
      username: `${userDetails?.username ?? ""}`,
      password: `${userDetails?.password ?? ""}`,
      confirm_password: `${userDetails?.password ?? ""}`,
    },
    shouldFocusError: true,
  });

  useEffect(() => {
    const buttonNextRef = refNextButton?.current;
    // TO TRIGGER THE API ONCES CAPTCHA IS SUCCESSFUL
    window.addEventListener("dd_response_passed", () => buttonNextRef?.click());

    return () =>
      window.removeEventListener("dd_response_passed", () =>
        buttonNextRef?.click()
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Language changed manually
  useEffect(() => {
    // Return validation to get errors in new locale
    Object.keys(errors).forEach((fieldName: any) => trigger(fieldName));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onDropdownChange = (countryName: string) => {
    if (globalConfigData.supportedCountries) {
      const selectedCountryCode = globalConfigData.supportedCountries.find(
        (countries: any) => countries.name === countryName
      )?.code;
      fetchGlobalConfigData({
        countryCode:
          selectedCountryCode || globalConfigData?.defaultCountry?.code,
      });
    }
  };

  const privacyPolicyUrl = useMemo(() => {
    return globalConfigData.userAgreements.find((item: any) =>
      item?.name?.toLowerCase()?.includes(PRIVACY_POLICY?.toLocaleLowerCase())
    )?.url;
  }, [globalConfigData]);

  const termAndConditionUrl = useMemo(() => {
    return globalConfigData.userAgreements.find((item: any) =>
      item?.name
        ?.toLowerCase()
        ?.includes(TERMS_AND_CONDITIONS?.toLocaleLowerCase())
    )?.url;
  }, [globalConfigData]);

  const isNextEnable = useMemo(
    () => isValid && isPrivacyPolicyChecked,
    [isValid, isPrivacyPolicyChecked]
  );

  const saveAccountInfo = async (data: any) => {
    const params = {
      first_name: data.first_name,
      last_name: data.last_name,
      username: data.username,
      password: data.password,
      country: globalConfigData?.defaultCountry?.id,
      email: data.email,
    };

    fetchAdminValidate(
      params,
      () => navigate("/signup/send-code"),
      errorCallback
    );
  };

  const errorCallback = (error: ApiError) => {
    setError(
      error.error_field,
      {
        type: "string",
        message: error.error_message,
      },
      { shouldFocus: true }
    );
  };

  const backHandler = () => {
    navigate(-1);
  };

  return {
    t,
    isPrivacyPolicyChecked,
    setIsPrivacyPolicyChecked,
    handleSubmit,
    register,
    errors,
    isNextEnable,
    privacyPolicyUrl,
    termAndConditionUrl,
    globalConfigData,
    hidePassword,
    setHidePassword,
    hideConfirmPassword,
    setHideConfirmPassword,
    onDropdownChange,
    saveAccountInfo,
    backHandler,
    loading,
    signupLoading,
    signUpApiError,
    clearSignUpApiError,
    refNextButton,
  };
};

export default ViewModel;
